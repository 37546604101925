import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public role: string;
  public superAdminOnlyRoutes = ["super_admin"];
  public adminRoutes = ["super_admin", "admin"];
  hideReportTab: boolean = environment.hideaReportTab || false;
  hidePasswordTab: boolean = environment.hidePasswordTab || false;
  hideBillingReport: boolean = environment.hideBillingReport || false;
  multipleCasesMenu: boolean = environment.multipleCasesMenu || false;
  public versionNo: string;
  public appendedCaseMenu: boolean = false;

  constructor(
    public menu: MenuController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    protected authenticationService: AuthenticationService,
    public translate: TranslateService,
    protected keycloakService: KeycloakService
  ) {
    this.initializeApp();
    translate.setDefaultLang("en");
    translate.use("en");
    this.role = this.keycloakService.getUserRoles()[0];
    // console.log('app role => ', this.role);
    const pj = require("../../package.json");
    this.versionNo = `v${pj.version}`;
    console.log(pj.version);
  }

  appendMenu(menuType: string) {
    if (this.appendedCaseMenu) {
      this.appendedCaseMenu = false;
    }
    else {
      this.appendedCaseMenu = true;
    }
  }

  initializeApp() {
    this.platform
      .ready()
      .then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  // checkLoginStatus() {
  //   return this.userData.isLoggedIn()
  //     .then(loggedIn => {
  //       return this.updateLoggedInStatus(loggedIn);
  //     });
  // }

  // updateLoggedInStatus(loggedIn: boolean) {
  //   setTimeout(() => {
  //     this.loggedIn = loggedIn;
  //     if (!loggedIn) {
  //       this.router.navigateByUrl('/login', { replaceUrl: true });
  //     }
  //   }, 300);
  // }

  // listenForLoginEvents() {
  //   window.addEventListener('user:login', () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener('user:signup', () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener('user:logout', () => {
  //     this.updateLoggedInStatus(false);
  //   });
  // }

  public logout(): void {
    this.authenticationService.doLogout();
  }
}
// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent {

// }

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService extends KeycloakAuthGuard {

  constructor(protected router: Router,
    private http: HttpClient,
    protected keycloakService: KeycloakService) {
    super(router, keycloakService);
  }
  public doLogout(): void {
    // FIXME then() is redundant
    this.keycloakService.logout().then();
  }

  public isStillLoggedIn(): boolean {
    console.log(this.authenticated)
    if (this.authenticated === undefined) {
      return false;
    }
    return this.authenticated;
  }
  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // console.log('isAccessAllowed1')
      this.http.post(`${environment.keycloak.url}/realms/${environment.keycloak.realm}/protocol/openid-connect/userinfo`, null)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch(() => {
          // console.log('logout1')
          this.keycloakAngular.login()
            .catch(e => console.error('asasas'));

          reject(false);
          // return;
        });
      // if (!this.authenticated) {
      //   this.keycloakAngular.login()
      //     .catch(e => console.error(e));

      //   reject(false);
      //   return;
      // }
      this.roles = this.keycloakService.getUserRoles();
      const requiredRoles: string[] = route.data.roles;
      // console.log('requiredRoles => ', requiredRoles);
      // console.log('route.data.roles => ', route.data.roles);
      // console.log('this.roles => ', this.roles);

      // const token = {
      //   token: this.keycloakService.getKeycloakInstance().token,
      //   refresh_token: this.keycloakService.getKeycloakInstance().refreshToken
      // };

      // var encodedStringBtoA = btoa(JSON.stringify(token));
      // console.log('token: ', token.token);
      // console.log('base64: ', encodedStringBtoA);

      // const headers = new HttpHeaders({ 'Authorization': `Bearer asasasasa` });

      // this.keycloakService.addTokenToHeader(headers).subscribe();

      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      }
      else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        resolve(requiredRoles.every(role => this.roles.indexOf(role) > -1));
      }
    });
  }
}


import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.multipleCasesMenu ? 'cases-nb' : 'cases',
    pathMatch: 'full'
  },
  {
    path: 'cases',
    loadChildren: () => import('./pages/cases/cases.module').then(m => m.CasesPageModule), canActivate: [AuthenticationService],
  },
  {
    path: 'cases-nb',
    loadChildren: () => import('./pages/cases/cases.module').then(m => m.CasesPageModule), canActivate: [AuthenticationService],
    data: {
      caseType: 'QUOTATION',
    },
  },
  {
    path: 'cases-ps',
    loadChildren: () => import('./pages/cases/cases.module').then(m => m.CasesPageModule), canActivate: [AuthenticationService],
    data: {
      caseType: 'SERVICE',
    },
  },
  {
    path: 'mandates',
    loadChildren: () => import('./pages/mandate/mandate.module').then(m => m.MandatePageModule), canActivate: [AuthenticationService],
    data: {
      roles: ['super_admin'],
    },
  },
  {
    path: 'configurations',
    loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationPageModule), canActivate: [AuthenticationService],
    data: {
      roles: ['super_admin'],
    },
  },
  // {
  //   path: 'report/far',
  //   loadChildren: () => import('./pages/report/report.module').then(m => m.ReportPageModule), canActivate: [AuthenticationService],
  //   data: {
  //     roles: ['super_admin'],
  //   },
  // },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule), canActivate: [AuthenticationService],
    data: {
      roles: ['super_admin'],
    },
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule), canActivate: [AuthenticationService],
  },
  {
    path: 'report/case',
    loadChildren: () => import('./pages/case-report/case-report.module').then(m => m.CaseReportPageModule), canActivate: [AuthenticationService],
    data: {
      roles: ['super_admin'],
    },
  },
  {
    path: 'report/billing',
    loadChildren: () => import('./pages/billing-report/billing-report.module').then(m => m.BillingReportPageModule), canActivate: [AuthenticationService],
    data: {
      roles: ['super_admin'],
    },
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./pages/page-not-found/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }